<template>
    <!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack()">
				<i class="icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10042')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
    <!-- // nav -->
    <div id="container" class="container">
		<div id="sub" class="sub terms">
            <!-- content -->
			<section class="content">
                <div class="row">
	                <ul class="layer__terms">
						<router-link to="/layers/terms/service" custom v-slot="{ navigate }">
							<li @click="navigate">
								<a>
									{{t('10043')}}
								</a>
								<i class="icon icon-right"></i>
							</li>
						</router-link>
		                <router-link to="/layers/terms/private" custom v-slot="{ navigate }">
							<li @click="navigate">
								<a>
									{{t('10044')}}
								</a>
								<i class="icon icon-right"></i>
							</li>
						</router-link>
		                <router-link to="/layers/terms/location" custom v-slot="{ navigate }">
							<li @click="navigate">
								<a>
									{{t('10045')}}
								</a>
								<i class="icon icon-right"></i>
							</li>
						</router-link>
						<router-link to="/layers/terms/youth" custom v-slot="{ navigate }">
							<li @click="navigate">
								<a>
									{{t('10046')}}
								</a>
								<i class="icon icon-right"></i>
							</li>
						</router-link>
	                </ul>
                </div>
			</section>
		</div>
	</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
    setup() {
        const router = useRouter();
		const { t }= useI18n() //번역필수 모듈
        return {
            goBack() {
                router.back();
            },
			t,
			i18n,
        }
    },
}
</script>